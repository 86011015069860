<template>
<div id="page-supervisores">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12>
                <base-material-card color="primary" icon="library_books" title="Administrar Supervisores" class="elevation-1 px-5 py-3">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
                            <v-icon dark>add</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-card-text>
                        <div id="datagrid">

                            <v-client-table ref="vuetable" :data="supervisores" :columns="columns" :options="options" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>

                                <template slot="actions" slot-scope="props" >
                                    <v-speed-dial direction="left" open-on-hover >
                                        <template v-slot:activator>
                                            <v-btn x-small fab dark color="primary">
                                                <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                                                <v-icon v-else>reorder</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-btn x-small fab dark color="primary"
                                            @click.native="imprimirCredencial(props.row)" title="Credencial" v-tippy>
                                            <v-icon>credit_card</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="blue" @click.native="getSupervisor(props.row._id)" title="Editar Registro" v-tippy>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="pink" @click.native="deleteSupervisor(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-speed-dial>
                                </template>
                            </v-client-table>
                        </div>
                    </v-card-text>
                </base-material-card>
            </v-flex>
        </v-layout>
    </v-container>

    <v-dialog v-model="modal" max-width="40%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>{{ update ? "Actualizar" : "Nuevo" }} Supervisor</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modal = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md @keyup.enter="update ? updateSupervisor() : saveSupervisor()">
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-autocomplete v-model="model.id_usuario" :items="usuarios.items"
                                :hide-no-data="true" :hide-selected="true" @change="setUsuario"
                                item-text="nombre" item-value="_id" label="Usuario" :rules="[rules.required, validaUsuario]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="9" md="9" lg="9">
                                <v-text-field label="Número de Identificación" v-model="model.no_identificacion"
                                :rules="[rules.required, validaNoIdentificacion]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                &nbsp;<br>
                                <v-btn small color="primary" @click="generaAleatorio()" >Aleatorio</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-text-field label="Código de Autorización" v-model="model.codigo"
                                hint="4 dígitos" persistent-hint maxlength="4"
                                :rules="[rules.required, rules.entero]"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modal = false">
                    <v-icon>cancel</v-icon> Cancelar
                </v-btn>
                <v-btn color="success" @click.native="update ? updateSupervisor() : saveSupervisor()">
                    <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import "jsbarcode";
import { jsPDF } from "jspdf";

export default {
    mounted: function () {
        if (!this.verificaPermiso('t.utilerias.supervisores')) {
            this.$router.replace("AccessDenied").catch(() => {});
        }
    },
    data() {
        return {
            loadingValoresSubtipo: false,
            fab: [],
            name: "datagrid",
            columns: [
                "usuario.nombre",
                "usuario.email",
                "no_identificacion",
                "actions"
            ],
            show_loading: true,
            options: {
                filterable: ["usuario.nombre","usuario.email","no_identificacion",],
                sortable: ["usuario.nombre","usuario.email","no_identificacion",],
                headings: {
                    "usuario.nombre": "Nombre",
                    "usuario.email": "Email",
                    'no_identificacion': "No. Identificación",
                    actions: "Operaciones"
                },
            },
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Utilerías",
                    disabled: true,
                    href: "/admin"
                },
                {
                    text: "Supervisores",
                    disabled: true,
                    href: ""
                }
            ],
            title: "",

            modal: false,

            update: false,

            model: {
                _id: "",
                _rev: "",
                id_usuario: "",
                usuario: {
                    nombre:"",
                    email:"",
                },
                type: "supervisores",
                no_identificacion: "",
                codigo: "",
            },
            rules: {
                required: v => !!v || "Este campo es requerido",
                entero: function(numero) {
                    const pattern = /^[\d]*$/;
                    return pattern.test(numero) || "Solo números";
                    },
            },
            search: "",
            supervisores: [],
            usuarios: {
                selected: [],
                headers: [{
                        text: "Nombre de Usuario",
                        value: "nombre"
                    },
                    {
                        text: "Email",
                        value: "email"
                    },
                    {
                        text: "Perfil",
                        value: "perfil"
                    },
                    {
                        text: "Estatus",
                        value: "estatus",
                        align: "center"
                    },
                    {
                        text: "Operaciones",
                        value: "operaciones",
                        align: "center"
                    }
                ],
                items: [{
                    perfil: 0
                }]
            },

        };
    },
    created: function () {
        this.getUsuarios();
        this.getSupervisores();
        //TODO: Validar que no se repita el usuario
        //Generar un número de identificación aleatorio y que se pueda editar, verificar que no se repita
    },
    methods: {

        validaNoIdentificacion:function(){
            var self = this;
            if (this.update == false){
                var filter = this.supervisores.find(function(s){
                    return s.no_identificacion == self.model.no_identificacion;
                });
                if (filter)
                    return "Número de Identificación ya existe";
            } else {
                var filter = this.supervisores.find(function(s){
                    return s.no_identificacion == self.model.no_identificacion && s._id != self.model._id;
                });
                if (filter)
                    return "Número de Identificación ya existe";
            }
            return true;
        },

        validaUsuario: function(){
            var self = this;
            if (this.update == false){
                var filter = this.supervisores.find(function(s){
                    return s.id_usuario == self.model.id_usuario;
                });
                if (filter)
                    return "Ya existe el usuario como supervisor";
            } else {
                var filter = this.supervisores.find(function(s){
                    return s.id_usuario == self.model.id_usuario && s._id != self.model._id;
                });
                if (filter)
                    return "Ya existe el usuario como supervisor";
            }
            return true;
        },
        setUsuario: function(){
            //buscar el this.model.id_usuario en usuarios y poner nombre y email
            var self = this;
            var filter = this.usuarios.items.find(function(u){
                return u._id == self.model.id_usuario;
            });
            if (filter){
                this.model.usuario.nombre = filter.nombre;
                this.model.usuario.email = filter.email;
                this.model.usuario.telefono = filter.telefono;
                this.model.usuario.cargo = filter.cargo;
            }
        },
        generaAleatorio: function(){
            var numero = "";
            for (var i = 0; i <= 9; i++) {
                numero +=  Math.floor(Math.random() * 99).toString();
            }
            this.model.no_identificacion = numero;
        },
        openModal: function (type) {
            if (type === 1) {
                this.title = "Nuevo Supervisor";
                this.update = false;
                this.model._id = "";
                this.model._rev = "";
                this.model.type = "supervisores";
                this.model.id_usuario = "";
                this.model.usuario = {
                    nombre:"",
                    email:"",
                    telefono:"",
                    cargo:""
                };
                this.model.no_identificacion = "";
                this.model.codigo = "";
                this.generaAleatorio();
            } else {
                this.title = "Actualizar Supervisor";
                this.update = true;
            }

            this.modal = true;
        },

        getSupervisores: function () {

            let where = {
                type: "supervisores",
            };
            let data = {
                selector: where
            }

            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0)
                        this.supervisores = response.data.docs;
                    else
                        this.supervisores = [];
                    window.dialogLoader.hide();
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los supervisores.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                });
        },
        getUsuarios: function () {

            let where = {
                type: "user",
                "estatus": "Activo",
            };
            let data = {
                selector: where,
                fields:["_id","nombre","name","telefono","cargo","email"]
            }

            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find', data)
                .then(response => {
                    if (response.data.docs.length > 0)
                        this.usuarios.items = response.data.docs;
                    else
                        this.usuarios.items = [];
                    window.dialogLoader.hide();
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los usuarios.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                });
        },

        getSupervisor: function (id_registro) {

            window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
                .then(response => {
                    this.model = response.data;
                    this.openModal(2);
                })
                .catch(error => {

                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener el registro",
                        footer: ""
                    });
                });
        },


        saveSupervisor: function () {
            if (this.$refs.form.validate()) {
                window.dialogLoader.show('Espere un momento por favor..');


                    let data = {
                        id_usuario: this.model.id_usuario,
                        usuario: this.model.usuario,
                        type: "supervisores",
                        no_identificacion: this.model.no_identificacion,
                        codigo: this.model.codigo,
                    };


                    window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
                        .then(response => {
                            this.modal = false;
                            window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                                color: 'success'
                            });
                            this.getSupervisores();
                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al guardar el registro",
                                footer: ""
                            });
                        }).then(() => {
                            window.dialogLoader.hide();
                        });

            }
        },
        updateSupervisor: function () {

            if (this.$refs.form.validate()) {
                window.dialogLoader.show('Espere un momento por favor..');

                    let data = this.model;

                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                        .then(response => {
                            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                                color: 'success'
                            });
                            this.getSupervisores();
                        })
                        .catch(error => {
                            console.log(error);
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al actualizar el registro",
                                footer: ""
                            });
                        }).then(() => {
                            this.modal = false;
                            window.dialogLoader.hide();
                        });

            }
        },
        deleteSupervisor: function (_id, _rev) {
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "¿Está seguro de eliminar el registro?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value) {
                    window.axios
                        .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
                        .then(response => {
                            window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                                color: 'success'
                            });
                            this.getSupervisores();
                        })
                        .catch(error => {
                            console.log(error);
                            window.dialogLoader.showSnackbar('Ocurrio un error al eliminar el registro..', {
                                color: 'error'
                            });
                        });

                }

            });
        },
        imprimirCredencial: function(row){
            try {
                var self = this;
                console.log("ROW: ", row);
                var doc = new jsPDF('p', 'mm', 'letter');


                doc.rect(25, 25, 75, 50, "S"); //Rectangulo

                var img = document.createElement("img");
                JsBarcode(img, row.no_identificacion, {format: "CODE128"});

                var logo = new Image(); //document.createElement("img");
                logo.src = "/static/base_credencial.png";

                //Base credencial
                doc.addImage(logo.src, 'PNG', 25, 25, 75, 50);

                //Nombre del usuario
                doc.setFontSize(6);
                doc.setFont("Helvetica","bold");
                doc.text(row.usuario.nombre.toString().toUpperCase(), 52, 32, {align:"center"});

                //Cargo
                doc.setFont("Helvetica","normal");
                doc.setTextColor('gray')
                try {
                    if(row.usuario.cargo)
                        doc.text(row.usuario.cargo, 52, 35, {align:"center"});    
                    else 
                        doc.text("  ", 52, 35, {align:"center"});
                } catch (error) {
                    doc.text("  ", 52, 35, {align:"center"});
                }
                

                //Código de Barras
                doc.addImage(img.src, 'PNG', 28, 37, 45, 15);

                //Teléfono y correo
                doc.setFont("Helvetica","bold");
                doc.setTextColor('black')
                try {
                    if(row.usuario.telefono)
                        doc.text("TEL. "+row.usuario.telefono, 87, 55, {align:"right"});    
                    else
                        doc.text(" ", 87, 55, {align:"right"});
                } catch (error) {
                    doc.text(" ", 87, 55, {align:"right"});
                }
                try {
                    if(row.usuario.email)
                        doc.text(row.usuario.email, 87, 60, {align:"right"});    
                    else
                        doc.text(" ", 87, 60, {align:"right"});
                } catch (error) {
                    doc.text(" ", 87, 60, {align:"right"});
                }
                                
                doc.save("credencial "+row.usuario.nombre+".pdf");
            } catch (error) {
                console.log(error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al generar la credencial. Intente nuevamente.",
                    footer: ""
                });
            }


        },

    }
};
</script>
